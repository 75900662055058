import { Injectable, OnDestroy } from '@angular/core';
import { LoginDTO } from '../components/auth/login/model/login';

const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {

  constructor() {
    window.addEventListener('storage', this.handleStorageEvent, false);
    this.requestSyncSessionStorage();
  }

  clean(): void {
    window.sessionStorage.clear();
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): LoginDTO {
    let obj: LoginDTO = {
      access_token: '',
      id_token: '',
      profile: {
        email: '',
        name: '',
        role: '',
        sub: ''
      }
    };
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      obj = JSON.parse(user);
    }
    return obj;
  }

  public isLoggedIn(): boolean {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }

  handleStorageEvent = (event: StorageEvent): void => {
    if (event.key === 'requestSyncSessionStorage') {
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('sessionStorage');
    } else if (event.key === 'sessionStorage') {
      const sessionStorage = JSON.parse(event.newValue || '{}');
      for (const key in sessionStorage) {
        window.sessionStorage.setItem(key, sessionStorage[key]);
      }
    }
  };

  requestSyncSessionStorage(): void {
    if (!sessionStorage.length) {
      const current = new Date().toLocaleTimeString();
      localStorage.setItem(
        'requestSyncSessionStorage',
        'request session storage ' + current
      );
    }
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.handleStorageEvent, false);
  }
}
