import { environment } from "src/environments/environment";

// API End Point
export const API_CONFIGS = environment.apiUrl + '/configs';
export const API_CONFIGS_BULK = environment.apiUrl + '/configs/-actions/bulkUpdate';
export const API_USERGROUP = environment.apiUrl + '/usergroups';
export const API_USER = environment.apiUrl + '/users';
export const API_REFERENCE = environment.apiUrl + '/references';
export const API_LOG_AUDIT = environment.apiUrl + '/audit-logs';
export const API_LOG_SERVICE = environment.apiUrl + '/audit-services';
export const API_AUTH_TOKEN = environment.apiUrl + '/auth-tokens';
export const API_EMPLOYEE = environment.apiUrl + '/employees';
export const API_ASSET = environment.apiUrl + '/assets';
export const API_PARTNER = environment.apiUrl + '/partners';
export const API_CONTACT = environment.apiUrl + '/contacts';
export const API_DOCUMENT = environment.apiUrl + '/documents';
export const API_ITEM = environment.apiUrl + '/items';

// Third parties
export const AOL_AUTH_URL = environment.accurateAol;
export const AOL_ACTUAL_REDIRECT_URL = environment.clientRoot + 'aol-callback/actual';
export const AOL_COMMERCIAL_REDIRECT_URL = environment.clientRoot + 'aol-callback/commercial';
export const AOL_SCOPE = 'employee_view customer_view customer_save vendor_view vendor_save item_view';
export const JIRA_AUTH_URL = environment.jiraUrl;
export const JIRA_SCOPE = '';
export const JIRA_REDIRECT_URL = encodeURI(environment.clientRoot + 'jira-callback');

// Common words
export const OK = 'Sukses';
export const FAIL = 'Gagal';
export const WARN = 'Peringatan';
export const UNCOMPLETE = 'Terdapat isian data yang belum diisi/dipilih, silahkan periksa kembali.';
export const WARNING = {
    DELETE: 'Silahkan pilih data yang ingin dihapus.',
};
export const RESPONSE = {
    STORE: 'Data berhasil ditambahkan.',
    UPDATE: 'Perubahan data berhasil disimpan.',
    DELETE: 'Data berhasil dihapus.',
    UPLOAD: 'Berkas berhasil diunggah.',
    ERROR: 'Terjadi masalah saat memproses data.',
    HTTP_500: 'Terjadi gangguan pada server, hubungi Administrator.',
    HINT_422: 'Terdapat data yang belum diisi/dipilih.',
    HINT_400: 'Payload request tidak sesuai format pada Back-end'
};


// Common default values
export const DEFAULT = {
    OPTION: {code: 'all', 'name': '---'},
    PERIOD: [new Date(), new Date()],
    TODAY: new Date(),
}
export const PUSHER = {
    KEY: environment.pusherKey,
    CLUSTER: environment.pusherCluster
}
