import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { environment } from "src/environments/environment";
import { AuthService } from "../components/auth/auth.service";
import { StorageService } from "../_services/storage.service";
import { NGXLogger } from 'ngx-logger';
import { Trait } from 'src/app/_helpers/trait';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private logger: NGXLogger
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = this.storageService.getUser().access_token !== '' ? this.storageService.getUser().access_token : Trait.HashText(environment.clientSecret);
    let userName = this.storageService.getUser().profile.name;
    let headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Username': userName
    });

    if (!req.url.includes('assets/store')) {
      headers = new HttpHeaders({
        'Content-Type': 'application/vnd.api+json',
        'Authorization': `Bearer ${token}`,
        'Username': userName
      });
    }

    req = req.clone({
      headers: headers,
      withCredentials: environment.httpCredential
    });

    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('private/login') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (this.storageService.isLoggedIn()) {
      this.authService.renewToken().then(user => {
        this.storageService.clean();
        this.storageService.saveUser(user);
      }).catch(err => this.logger.error(err));
    }

    return next.handle(request);
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];
