import { OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    ngOnInit() {
        this.model = [
            {
                label: 'BERANDA',
                icon: 'pi pi-home',
                items: [
                    {
                        label: 'Dasbor',
                        icon: 'pi pi-fw pi-home',
                        routerLink: ['/private/home/dashboard']
                    },
                    {
                        label: 'Kalender Kerja',
                        icon: 'pi pi-fw pi-calendar',
                        routerLink: ['/private/home/calendar']
                    },
                ]
            },
            { separator: true },
            {
                label: 'PEMASARAN',
                icon: 'pi pi-cart-plus',
                items: [
                    {
                        label: 'Aktivitas',
                        icon: 'pi pi-fw pi-prime',
                        routerLink: ['/private/marketing/activity']
                    },
                    {
                        label: 'Barang & Jasa',
                        icon: 'pi pi-fw pi-gift',
                        routerLink: ['/private/marketing/items']
                    },
                    {
                        label: 'Pemasok',
                        icon: 'pi pi-fw pi-globe',
                        routerLink: ['/private/marketing/vendor']
                    },
                    {
                        label: 'Pelanggan',
                        icon: 'pi pi-fw pi-user-plus',
                        routerLink: ['/private/marketing/customer']
                    },
                ]
            },
            { separator: true },
            {
                label: 'KONSULTAN PABEAN',
                icon: 'pi pi-link',
                items: [
                    {
                        label: 'Aktivitas',
                        icon: 'pi pi-fw pi-inbox',
                        routerLink: ['/private/customs/activity']
                    },
                    {
                        label: 'KITE Tool',
                        icon: 'pi pi-fw pi-briefcase',
                        routerLink: ['/private/customs/kitetool']
                    },
                ]
            },
            { separator: true },
            {
                label: 'TEKNOLOGI INFORMASI',
                icon: 'pi pi-power-off',
                items: [
                    {
                        label: 'Dukungan Teknis',
                        icon: 'pi pi-fw pi-wrench',
                        routerLink: ['/private/infotech/support']
                    },
                    {
                        label: 'Kredensial',
                        icon: 'pi pi-fw pi-lock',
                        routerLink: ['/private/infotech/credential']
                    }
                ]
            },
            { separator: true },
            {
                label: 'KEARSIPAN',
                icon: 'pi pi-fw pi-compass',
                items: [
                    {
                        label: 'Pengaturan Arsip',
                        icon: 'pi pi-fw pi-tags',
                        routerLink: ['/private/archives/archive']
                    },
                    {
                        label: 'Kelola Arsip',
                        icon: 'pi pi-fw pi-book',
                        routerLink: ['/private/archives/colors']
                    },
                ]
            },
            { separator: true },
            {
                label: 'SDM & UMUM',
                icon: 'pi pi-th-large',
                items: [
                    {
                        label: 'Kepegawaian',
                        icon: 'pi pi-fw pi-sitemap',
                        items: [
                            {
                                label: 'Pegawai',
                                icon: 'pi pi-fw pi-user',
                                routerLink: ['/private/humans/employee']
                            },
                            {
                                label: 'Presensi',
                                icon: 'pi pi-fw pi-clock',
                                routerLink: ['/private/humans/attendance']
                            },
                            {   
                                label: 'Cuti',
                                icon: 'pi pi-fw pi-file',
                                routerLink: ['/private/humans/leave']
                            },
                        ]
                    },
                    {
                        label: 'Umum',
                        icon: 'pi pi-fw pi-copy',
                        items: [
                            {
                                label: 'Form PPBJ',
                                icon: 'pi pi-fw pi-file',
                                routerLink: ['/private/generals/procurement']
                            },
                        ]
                    },
                ]
            },
            { separator: true },
            {
                label: 'PENGATURAN',
                icon: 'pi pi-cog',
                items: [
                    {
                        label: 'Utilitas',
                        icon: 'pi pi-fw pi-cog',
                        routerLink: ['/private/utilities/utility']
                    },
                    {
                        label: 'Grup Pengguna',
                        icon: 'pi pi-fw pi-users',
                        routerLink: ['/private/auth/usergroup']
                    },
                    {
                        label: 'Pengguna',
                        icon: 'pi pi-fw pi-user',
                        routerLink: ['/private/auth/user']
                    },
                    {
                        label: 'Referensi',
                        icon: 'pi pi-fw pi-database',
                        routerLink: ['/private/utilities/reference']
                    },
                    {
                        label: 'Log Audit',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/private/utilities/log-audit']
                    },
                    {
                        label: 'Log Service',
                        icon: 'pi pi-fw pi-list',
                        routerLink: ['/private/utilities/log-service']
                    },
                ]
            },
        ];
    }
}
