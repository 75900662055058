import { HttpErrorResponse } from "@angular/common/http";
import { ErrorData } from "../_models/common-dto";
import { DataGridRequest } from "../_models/datagrid";
import * as constant from 'src/app/_helpers/constant';
import * as dayjs from 'dayjs';
import { hashSync, genSaltSync } from "bcryptjs";

dayjs.locale('id');
const saltRounds = 10;

export const Trait = {
    HashText(param: string): string {
        const salt = genSaltSync(saltRounds);
        return hashSync(param, salt);
    },

    ErrorResponse(err: HttpErrorResponse): ErrorData {
        switch (err.status) {
            case 422:
                let _422: ErrorData = {
                    title: err.statusText,
                    detail: err.error.message,
                    hint: constant.RESPONSE.HINT_422
                }
                return _422
                break;
            case 400:
                let _400: ErrorData = {
                    title: err.error.errors[0].title,
                    detail: err.error.errors[0].detail,
                    hint: constant.RESPONSE.HINT_400
                }
                return _400
                break;
            default:
                let errData = this.ErrorHint(err.error.errors[0].detail)
                let _500: ErrorData = {
                    title: err.statusText,
                    detail: IsNotNullable(err.error.errors[0]) ? errData[0] : constant.RESPONSE.HTTP_500,
                    hint: errData[1]
                }
                return _500
                break;
        }
    },

    ErrorHint(err: string): any {
        if (err.includes('[Galat]')) {
            let params = err.split('[Galat]');
            return [params[0], params[1]];
        } else {
            return [err, '']
        }
    },

    PageOffset(offset: number, limit: number): number {
        let result = (offset + 2) / limit;
        return Math.round(result) + 1;
    },

    SingleSortOrder(order: string): string {
        return order === "desc" ? "-" : ""
    },

    RequestParams(payload: DataGridRequest): string {
        let singleFilter = ''
        let arrayFilter = ''
        if (payload.filters.length > 0) {
            payload.filters.forEach(el => {
                if (!el.arr && el.val !== undefined && el.val !== 'all' && el.val !== '') {
                    singleFilter += '&filter['+el.key+']='+el.val
                }
                if (el.arr && el.val !== undefined && el.val !== 'all' && el.val !== '') {
                    arrayFilter += '&filter['+el.key+'][]='+el.val
                }
            });
        }
        return '?page[number]='+payload.offset+'&page[size]='+payload.limit+'&sort='+this.SingleSortOrder(payload.sortOrder)+payload.sortField+singleFilter+arrayFilter;
    },

    RoleName(name: string): string {
        if (name.includes('_')) {
            let names = name.split('_')
            for (let i = 0; i < names.length; i++) {
                names[i] = names[i][0].toUpperCase() + names[i].substring(1)
            }
            return names.join(' ')
        } else {
            return name[0].toUpperCase() + name.substring(1)
        }
    },

    RoleActionId(name: string): string {
        let names = name.split('-');
        return names[0]
    },

    RoleActionName(name: string): string {
        let names = name.split('-');
        switch (names[1]) {
            case 'show':
                return 'Lihat';
                break;
            case 'store':
                return 'Tambah';
                break;
            case 'update':
                return 'Ubah';
                break;
            case 'destroy':
                return 'Hapus';
                break;
            case 'bulkUpdate':
                return 'Ubah';
                break;
        
            default:
                return 'Akses menu'
                break;
        }
    },
    
    FilterArray(arr: Array<string>, filter: string) {
        let result = []
        for (let index:number = 0; index < arr.length; index++) {
            if (arr[index]['key' as any] != filter) {
                result.push({key: arr[index]['key' as any], value: arr[index]['value' as any]})
            }
        }
        return result
    },

    GetArrayValueByKey(arr: Array<any>, key: string | any, find: string, val: string | any) {
        let result: any
        for (let index = 0; index < arr.length; index++) {
            if (arr[index][key] == find) {
                result = arr[index][val]
            }
        }
        return result
    },
    
    PopulateList(param: Array<string>) {
        let el = '<ul class="m-0">'
        for (let index = 0; index < param.length; index++) {
            el += '<li>'+param[index]+'</li>'
        }
        el += '</ul>'
        return el
    },

    GetSplitIndex(param: string, delimiter: string, index: number) {
        let values = param.split(delimiter)
        return values[index]
    },

    CommaSeparateNumber(val:number) {
        var sign = 1;
        if (val < 0) {
            sign = -1;
            val = -val;
        }
        let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString()
        while (/(\d+)(\d{3})/.test(num.toString())) {
            num = num.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
        }
        if (val.toString().includes('.')) {
            num = num + '.' + val.toString().split('.')[1]
        }
        return sign < 0 ? '-' + num : num
    },

    CurrencyFormat(val: number) {
        var sign = 1;
        if (val < 0) {
            sign = -1;
            val = -val;
        }
        let num = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString()
        while (/(\d+)(\d{3})/.test(num.toString())) {
            num = num.toString().replace(/(\d+)(\d{3})/, '$1' + '.' + '$2')
        }
        if (val.toString().includes('.')) {
            num = num + ',' + val.toString().split('.')[1]
        }
        return sign < 0 ? '-' + num : num
    },

    StringToFloat(val: string) {
        return parseFloat(val.replace(/[&\/\\#,+()$~%'":*?<>{}]/g,''))
    },

    RemoveCurrencyFormat(val: string) {
        let param = val.slice(2)
        return parseFloat(param.replace(/[&\/\\#,+()$~%'":*?<>{}]/g,''))
    },

    ParseCurrencyFormat(val: string) {
        let param = val.slice(2).split(',')
        let value = param[0].replace(/[&\/\\#.+()$~%'":*?<>{}]/g, '')
        return parseFloat(value + '.' + param[1])
    },

    ParseDecimalFormat(val: string) {
        let param = val.split(',')
        let value = param[0].replace(/[&\/\\#.+()$~%'":*?<>{}]/g, '')
        return parseFloat(value + '.' + param[1])
    },

    RandomString(length: number) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
        let result = ''
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result
    },

    SumArray(param: Array<number>) {
        let sum = 0
        for (let i = 0; i < param.length; i += 1) {
            sum += param[i]
        }
        return sum
    },

    TruncateText(text:string, maxLength:number) {
        if (text.length > maxLength) {
            text = text.substring(0,maxLength) + '...'
        }
        return text
    },

    FormatDate(date: Date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        return [year, month, day].join('-');
    },

    StringToDateFormat(param: string): string {
        return dayjs(param).format("DD/MM/YYYY").toString();
    },

    ObjectToQuery(param: any): string {
        let s = "";
        for (let key in param) {
            if (s != "") {
                s += "&";
            }
            s += (key + "=" + encodeURIComponent(param[key]));
        }
        return s;
    },

}

export const IsNotNullable = <T>(element: T | null | undefined): element is T =>
    typeof element !== "undefined" && element !== null;